<template>
  <CookiePrimaryModal
    v-model:is-show-modal="isShowCookiePrimaryModal"
    v-model:is-show-cookie-secondary-modal="isShowCookieSecondaryModal"
    :set-cookie="setCookie"
    :update-cookie-value="updateCookieValue"
  />
  <CookieSecondaryModal
    v-model:is-show-modal="isShowCookieSecondaryModal"
    v-model:is-show-cookie-primary-modal="isShowCookiePrimaryModal"
    :set-cookie="setCookie"
    :update-cookie-value="updateCookieValue"
  />
</template>

<script setup lang="ts">
import { CookieOptionsKeys, CookieOptionsValues } from "~/types/entities";
import { cookieValueKey } from "~/symbols";
import CookiePrimaryModal from "~/components/modals/CookiePrimaryModal.vue";
import CookieSecondaryModal from "~/components/modals/CookieSecondaryModal.vue";
import { setCookies } from "~/api/cookies";

const { initialize } = useGtag();
const { cookiePolicyKey } = useRuntimeConfig().public;

interface CookieBlockProps {
  isShowModal: Ref<boolean>;
}

const props = defineProps<CookieBlockProps>();

const isShowCookiePrimaryModal = ref(true);
const isShowCookieSecondaryModal = ref(false);

const emit = defineEmits([
  "update:isShowModal",
]);

watch (isShowCookiePrimaryModal, () => {
  console.log('show cookie' + isShowCookiePrimaryModal.value);
  if (!isShowCookiePrimaryModal.value) {
    emit('update:isShowModal', false);
  }
})

const cookieValue = ref<CookieOptionsValues>({
  [CookieOptionsKeys.Technical]: true,
  [CookieOptionsKeys.Analytical]: true,
});
provide(cookieValueKey, cookieValue);

const updateCookieValue = (value: CookieOptionsValues): void => {
  cookieValue.value = value;
};

const setCookie = async (): Promise<void> => {
  try {
    await setCookies(cookieValue.value);
    checkAnalyticsPermission();
  } catch (error) {
    console.error(error);
  }
};

const checkAnalyticsPermission = () => {
  if (getParsedCookie(cookiePolicyKey)?.[CookieOptionsKeys.Analytical]) {
    initialize();
  }
};
</script>
