<template>
  <ModalContainer
    :is-show-modal="isShowModal"
    class="cookie-modal cookie-primary-modal"
  >
    <template #header>
      <h2 class="cookie-primary-modal__title">{{ $t("Cookie Policy") }}</h2>
    </template>
    <div class="cookie-primary-modal__wrapper">
      <p class="cookie-primary-modal__text">
        {{
          $t(
            "To ensure the convenience of site users, cookies are used. You can:"
          )
        }}
      </p>
      <BaseButton
        :text="true"
        class="cookie-primary-modal__text-button"
        @click="handleModalCloseAndCookie()"
        >{{ $t("Reject") }}</BaseButton
      >
      <BaseButton
        :text="true"
        class="cookie-primary-modal__text-button"
        @click="$emit('update:isShowCookieSecondaryModal', true)"
        >{{ $t("Set cookies") }}</BaseButton
      >
    </div>
    <BaseButton
      class="cookie-primary-modal__confirm"
      @click="handleModalCloseAndCookie()"
      >{{ $t("Confirm") }}</BaseButton
    >
  </ModalContainer>
</template>

<script setup lang="ts">
import { CookieOptionsValues } from "~/types/entities";
import ModalContainer from "~/components/modals/ModalContainer.vue";
import BaseButton from "~/components/button/BaseButton.vue";
import { cookieValueKey } from "~/symbols";

interface CookiePrimaryModalProps {
  isShowModal: boolean;
  isShowCookieSecondaryModal: boolean;
  setCookie: () => void;
  updateCookieValue: (value: CookieOptionsValues) => void;
}

const props = withDefaults(defineProps<CookiePrimaryModalProps>(), {
  isShowModal: false,
  isShowCookieSecondaryModal: false,
});

const emit = defineEmits([
  "update:isShowModal",
  "update:isShowCookieSecondaryModal",
]);

const cookieValue = <Ref<CookieOptionsValues>>inject(cookieValueKey);

const handleModalCloseAndCookie = (): void => {
  emit("update:isShowModal", false);
  props.updateCookieValue(cookieValue.value);
  props.setCookie();
};
</script>
