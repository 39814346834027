<template>
  <ModalContainer
    :is-show-modal="isShowModal"
    :nested-dialog="true"
    class="cookie-modal cookie-secondary-modal"
    @close="closeModal"
  >
    <template #header>
      <h2 class="cookie-secondary-modal__title">
        {{ $t("About the cookie policy") }}
      </h2>
      <IconButton icon="cross" @click="closeModal" />
    </template>
    <div class="cookie-secondary-modal__wrapper">
      <ContentDoc
        :path="cookieContent._path"
        :head="false"
        @checkbox-change="onChange"
        class="cookie-secondary-modal__text"
      />
      <BaseButton
        class="cookie-secondary-modal__confirm"
        @click="closeModalsAndSetCookie"
        >{{ $t("Confirm your cookie settings") }}</BaseButton
      >
    </div>
  </ModalContainer>
</template>

<script setup lang="ts">
import { CookieOptionsValues } from "~/types/entities";
import ModalContainer from "~/components/modals/ModalContainer.vue";
import IconButton from "~/components/button/IconButton.vue";
import BaseButton from "~/components/button/BaseButton.vue";

interface CookieSecondaryModalProps {
  isShowModal: boolean;
  isShowCookiePrimaryModal: boolean;
  setCookie: () => void;
  updateCookieValue: (value: CookieOptionsValues) => void;
}

const props = withDefaults(defineProps<CookieSecondaryModalProps>(), {
  isShowModal: false,
});

const emit = defineEmits([
  "update:isShowModal",
  "update:isShowCookiePrimaryModal",
]);

const { locale } = useI18n();

const cookieContent = await queryContent(`${locale.value}/cookie`)
  .where({ title: "Cookie" })
  .findOne();

const closeModal = (): void => {
  emit("update:isShowModal", false);
};

const onChange = (value: CookieOptionsValues) => {
  props.updateCookieValue(value);
};

const closeModalsAndSetCookie = (): void => {
  emit("update:isShowModal", false);
  emit("update:isShowCookiePrimaryModal", false);
  props.setCookie();
};
</script>
